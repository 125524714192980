import IntlTelInput from "react-intl-tel-input";
import { useTranslation } from "react-i18next";
import "react-intl-tel-input/dist/main.css";
import "./inputs.scss";
import { useField } from "formik";
import debounce from "lodash.debounce";


const PhoneNumberField = ({label, errors, touched, requiredStyle, ...props}) => {
  const [, , helpers] = useField(props);
  const { setError, setValue, setTouched } = helpers;
  const { t, } = useTranslation();

  return (
    <div key={props.id} className={"row mb-3 m-md-3 " + (errors && touched ? "errors" : "")}>
      <label
        key="label"
        htmlFor={props.id}
        className={"col-md-4" + (requiredStyle ? " required" : "")}
      >
        <span className="label-text">{label}</span>
        <div>
          <small>{props.help_text}</small>
        </div>
      </label>
      <div className="col col-md-8">
        <IntlTelInput
          fieldName={props.name}
          containerClassName="intl-tel-input"
          inputClassName="form-control"
          onPhoneNumberBlur={(event) => {
            setTouched(true, true);
          }}
          onPhoneNumberChange={
            debounce(
              (isValid, value, selectedCountryData, fullNumber) => {
                setTouched(true, true);
                setValue(fullNumber, true);
                !isValid && setError([t("The phone number is not valid.")]);
              },
              100,
            )
          }
          customPlaceholder={props.placeholder}
        />
        {errors && touched && errors.map((error) => {
          return <div key={error} className="validation-error">{error}</div>
        })}
      </div>
    </div>
  );
};

export default PhoneNumberField;
