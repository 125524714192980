import { version } from "../package.json";

const settings = {
  baseApiUri:
    process.env.REACT_APP_BASE_API_URI || "http://localhost:3000/api",
  environment: process.env.NODE_ENV || "development",
  debug: process.env.REACT_APP_DEBUG === "true",
  mautic_script_uri: process.env.REACT_APP_MAUTIC_SCRIPT_URI,
  sentry_dsn: process.env.REACT_APP_SENTRY_DSN,
  supportEmail: process.env.REACT_APP_SUPPORT_EMAIL || "support-email@example.com.fake",
  version: version,
  maxFileSize: process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE || 1 * 1024 ** 2,
  maxFileSizeString: process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE_STRING || "1MB"
};

export default settings;
