import Navigation from './components/navigation/Navigation.jsx';
import Footer from './components/navigation/Footer.jsx';
import JobApplication from './components/pages/JobApplication/JobApplication.jsx';
import Success from './components/pages/Success/Success.jsx';
import { Redirect, BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { getUUID } from "./utils.js"

import headerimage from './assets/header_image.svg';
import './App.scss';

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
          <div className="container">
            <img src={headerimage} className="App-header-image img-responsive my-5 mx-auto d-block" alt="logo" />

            <Switch>
              <Route
                path="/:muApplicantUuid/application"
                component={JobApplication}
              ></Route>
              <Route
                path="/success"
                component={Success}
              ></Route>
              <Redirect from="/" to={`/${ getUUID().replace(/-/g, "") }/application`} />
            </Switch>
          </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
