import "./JobApplication.scss";

import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { Formik, Field, Form } from "formik";
import InputField from "../../inputs/InputField.js";
import FileUploadField from "../../inputs/FileUploadField.js";
import GroupField from "../../inputs/GroupField.js";
import PhoneNumberField from "../../inputs/PhoneNumberField.js";
import { Redirect }  from 'react-router-dom';
import { Trans } from 'react-i18next';
import Sentry from "../../../sentry.js";

import { submitApplication } from "../../../services/SubmitService.js";
import { useParams } from "react-router-dom";
import timezones from "../timezones.js";
import {
  validateEmail,
  validateMaxFileSize,
  validateFileType,
  validatePhoneNumber,
  isRequired,
  notRequired,
  validateIsFilled,
  hasErrorAndIsTouched,
} from "../../../utils.js"
import debounce from "lodash.debounce";
import mautic from "../../../mautic.js"
import settings from "../../../settings.js";


function JobApplication() {
  const { muApplicantUuid } = useParams();
  const [isSubmittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const [failureMessage, setFailureMessage] = useState();
  const { t, i18n } = useTranslation();

  const getPhoneNumberPlaceholder = (placeholder, selectedCountryData) => {
    return t(
      "Example: {{exampleNumber}}",
      {exampleNumber: placeholder},
    )
  }

  useEffect(
    () => {
      Sentry.setUser({ id: muApplicantUuid });
      Sentry.setTag("browser_locale", i18n.language);
      mautic("send", "pageview");
    },
    [muApplicantUuid, i18n.language],
  );

  return (
    <>
      { isSubmittedSuccessfully ? <Redirect  to="/success" /> : "" }
      <header>
        <h1>{t("Apply for a missional job")}</h1>
      </header>
      <div className="container">
        <Formik
          initialValues={{
            mu_applicant_uuid: muApplicantUuid,
            position: "",
            full_name: "",
            name_you_go_by: "",
            email: "",
            secondary_email: "",
            mobile_phone: "",
            skype_handle: "",
            whatsapp_number: "",
            signal_number: "",
            google_voice_number: "",
            complete_mailing_address: "",
            timezone: "",
            undergraduate_degree: "",
            undergraduate_degree_school: "",
            masters_degree: "",
            masters_degree_school: "",
            masters_degree_thesis: "",
            doctorate_degree: "",
            doctorate_degree_school: "",
            doctorate_degree_thesis: "",
            current_job: "",
            areas_of_expertise: "",
            church_name: "",
            church_denomination_name: "",
            missions_experience: "",
            countries: "",
            languages: "",
            agree_with_statement_of_faith: "",
            agree_with_statement_of_faith_why: "",
            agree_with_ethos_statement: "",
            agree_with_ethos_statement_why: "",
            cv: "",
            transcripts: "",
            photo: "",
            testimony: "",
            faculty_teaching_experience: [],
          }}

          onSubmit={debounce(
                async (formData, actions) => {
                let submitData = {...formData};
                actions.setSubmitting(true);
                setFailureMessage(); // Clear the failure message
                Sentry.setUser({
                  id: muApplicantUuid,
                  email: formData.email,
                  full_name: formData.full_name,
                  position: formData.position,
                  secondary_email: formData.secondary_email,
                });
                submitData.agree_with_statement_of_faith = submitData.agree_with_statement_of_faith === "Yes";
                submitData.agree_with_ethos_statement = submitData.agree_with_ethos_statement === "Yes";
                Sentry.setContext("formData", formData);

                mautic(
                  "send",
                  "pageview",
                  {page_url: "submit-application-form"},
                );

                submitApplication(submitData).then((data) => {
                  actions.setSubmitting(false);

                  if (data.status === "success") {
                    setSubmittedSuccessfully(true);
                  } else if (data.status === "error") {
                    actions.setErrors(data.errors);
                  } else if (data.status === "failure") {
                    actions.setSubmitting(false);
                    setSubmittedSuccessfully(false);
                    setFailureMessage(data.message);
                  }
                })
                .catch(() => {
                  actions.setSubmitting(false);
                  setSubmittedSuccessfully(false);
                  setFailureMessage(t(
                    "We could not process your application. Please try again later or contact support at {{supportEmail}}.",
                    {supportEmail: settings.supportEmail},
                  ));
                });
              },
              1000,
              {maxWait: 1000},
            )
          }

          validate={(values) => {
            const errors = {};

            if (values.agree_with_statement_of_faith === "No" && !values.agree_with_statement_of_faith_why) {
              errors.agree_with_statement_of_faith_why = [t("You must explain why you disagree.")]
            }
            if (values.agree_with_ethos_statement === "No" && !values.agree_with_ethos_statement_why) {
              errors.agree_with_ethos_statement_why = [t("You must explain why you disagree.")]
            }

            return errors;
          }}
        >
          {(props) => (
            <Form key="application-form">
              <div className="card m-3">
                <div className="card-body">
                  <InputField
                    id="position"
                    name="position"
                    label={t("Position")}
                    help_text={t("To which job are you applying?")}
                    requiredStyle={true}
                    placeholder={t("Example: HR Manager (Zone 1)")}
                    validate={validateIsFilled(t("This field is required"))}
                    className="form-control"
                    errors={props.errors.position}
                    touched={props.touched.position}
                  />
                </div>
              </div>

              <div className="card m-3">
                <div className="card-body">
                  <div className="row m-md-3 my-2">
                    <div className="col-12">
                      <h2 className="card-title">{t("Contact information")}</h2>
                    </div>
                  </div>
                  <InputField
                    id="fullName"
                    name="full_name"
                    label={t("Full name")}
                    requiredStyle={true}
                    help_text={t("What is your full legal name?")}
                    validate={validateIsFilled(t("This field is required"))}
                    className="form-control"
                    errors={props.errors.full_name}
                    touched={props.touched.full_name}
                  />

                  <InputField
                    id="nameYouGoBy"
                    name="name_you_go_by"
                    label={t("Nickname")}
                    help_text={t("This is an optional name that you like to be called.")}
                    className="form-control"
                    errors={props.errors.name_you_go_by}
                    touched={props.touched.name_you_go_by}
                  />

                  <InputField
                    id="email"
                    name="email"
                    label={t("Primary email")}
                    requiredStyle={true}
                    help_text={t("What is your main email address?")}
                    placeholder={t("Example: example@example.com")}
                    validate={
                      isRequired(
                        t("This field is required"),
                        validateEmail(
                          t("The email address is not valid.")
                        ),
                      )
                    }
                    className="form-control"
                    errors={props.errors.email}
                    touched={props.touched.email}
                  />

                  <InputField
                    id="secondaryEmail"
                    name="secondary_email"
                    label={t("Secondary email")}
                    help_text={t("What is another email address where you can be reached?")}
                    placeholder={t("Example: another@example.com")}
                    validate={notRequired(
                      validateEmail(t("The email address is not valid.")),
                    )}
                    className="form-control"
                    errors={props.errors.secondary_email}
                    touched={props.touched.secondary_email}
                  />
                  <PhoneNumberField
                    id="mobilePhone"
                    name="mobile_phone"
                    label={t("Mobile cell phone")}
                    help_text={t("Please select the flag that corresponds to your international calling code and enter your number.")}
                    requiredStyle={true}
                    placeholder={getPhoneNumberPlaceholder}
                    validate={isRequired(
                      t("This field is required"),
                      validatePhoneNumber(t("The phone number is not valid."))
                    )}
                    className="form-control"
                    errors={props.errors.mobile_phone}
                    touched={props.touched.mobile_phone}
                  />

                  <InputField
                    id="skypeHandle"
                    name="skype_handle"
                    label={t("Skype name")}
                    className="form-control"
                    errors={props.errors.skype_handle}
                    touched={props.touched.skype_handle}
                  />

                  <PhoneNumberField
                    id="WhatsAppNumber"
                    name="whatsapp_number"
                    label={t("WhatsApp number")}
                    help_text={t("Please select the flag that corresponds to your international calling code and enter your number.")}
                    placeholder={getPhoneNumberPlaceholder}
                    validate={notRequired(
                      validatePhoneNumber(t("The phone number is not valid."))
                    )}
                    className="form-control"
                    errors={props.errors.whatsapp_number}
                    touched={props.touched.whatsapp_number}
                  />

                  <PhoneNumberField
                    id="SignalNumber"
                    name="signal_number"
                    label={t("Signal number")}
                    help_text={t("Please select the flag that corresponds to your international calling code and enter your number.")}
                    placeholder={getPhoneNumberPlaceholder}
                    validate={notRequired(
                      validatePhoneNumber(t("The phone number is not valid."))
                    )}
                    className="form-control"
                    errors={props.errors.signal_number}
                    touched={props.touched.signal_number}
                  />

                  <PhoneNumberField
                    id="GoogleVoiceNumber"
                    name="google_voice_number"
                    label={t("Google Voice number")}
                    help_text={t("Please select the flag that corresponds to your international calling code and enter your number.")}
                    placeholder={getPhoneNumberPlaceholder}
                    validate={notRequired(
                      validatePhoneNumber(t("The phone number is not valid."))
                    )}
                    className="form-control"
                    errors={props.errors.google_voice_number}
                    touched={props.touched.google_voice_number}
                  />

                  <InputField
                    id="completeMailingAddress"
                    name="complete_mailing_address"
                    label={t("Complete mailing address")}
                    requiredStyle={true}
                    help_text={t("Please include postal codes and country")}
                    validate={validateIsFilled(t("This field is required"))}
                    className="form-control complete-mailing-address"
                    as="textarea"
                    errors={props.errors.complete_mailing_address}
                    touched={props.touched.complete_mailing_address}
                  />

                  <div key="timezone" className={"row m-md-3" + (props.errors.timezone && props.touched.timezone ? " errors" : "")}>
                    <label htmlFor="timezone" className="col-md-4 required">
                      <span className="label-text">{t("Timezone")}</span>
                    </label>
                    <div className="col-md-8">
                      <Field
                        id="timezone"
                        name="timezone"
                        placeholder={t("What is your local timezone?")}
                        className="form-control"
                        validate={validateIsFilled(t("This field is required"))}
                        as="select"
                      >
                        <option value="">--- {t("Please select a timezone")} ---</option>
                        {
                          Object.entries(timezones).map(([label, value]) => {
                            return (
                              <option key={value} value={value}>{label}</option>
                            )
                          })
                        }
                      </Field>
                      {props.errors.timezone && props.touched.timezone && props.errors.timezone.map((error) => {
                        return <div key={error} className="validation-error">{error}</div>
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card m-3">
                <div className="card-body">
                  <div className="row m-md-3 my-2">
                    <div className="col-12">
                      <h2 className="card-title">{t("Academic information")}</h2>
                    </div>
                  </div>
                  <InputField
                    id="undergraduateDegree"
                    name="undergraduate_degree"
                    label={t("Undergraduate degree")}
                    className="form-control"
                    errors={props.errors.undergraduate_degree}
                    touched={props.touched.undergraduate_degree}
                  />

                  <InputField
                    id="undergraduateDegreeSchool"
                    name="undergraduate_degree_school"
                    label={t("School of undergraduate degree")}
                    className="form-control"
                    errors={props.errors.undergraduate_degree_school}
                    touched={props.touched.undergraduate_degree_school}
                  />

                  <InputField
                    id="mastersDegree"
                    name="masters_degree"
                    label={t("Master's degree")}
                    className="form-control"
                    errors={props.errors.masters_degree}
                    touched={props.touched.masters_degree}
                  />

                  <InputField
                    id="mastersDegreeSchool"
                    name="masters_degree_school"
                    label={t("School of master's degree")}
                    className="form-control"
                    errors={props.errors.masters_degree_school}
                    touched={props.touched.masters_degree_school}
                  />

                  <InputField
                    id="mastersDegreeThesis"
                    name="masters_degree_thesis"
                    label={t("Title of master's thesis")}
                    help_text={t("(if applicable)")}
                    className="form-control"
                    errors={props.errors.masters_degree_thesis}
                    touched={props.touched.masters_degree_thesis}
                  />

                  <InputField
                    id="doctorate"
                    name="doctorate_degree"
                    label={t("Doctorate degree")}
                    className="form-control"
                    errors={props.errors.doctorate_degree}
                    touched={props.touched.doctorate_degree}
                  />

                  <InputField
                    id="doctorateDegreeSchool"
                    name="doctorate_degree_school"
                    label={t("School of doctorate degree")}
                    className="form-control"
                    errors={props.errors.doctorate_degree_school}
                    touched={props.touched.doctorate_degree_school}
                  />

                  <InputField
                    id="doctorateDegreeThesis"
                    name="doctorate_degree_thesis"
                    label={t("Title of doctoral dissertation")}
                    help_text={t("(if applicable)")}
                    className="form-control"
                    errors={props.errors.doctorate_degree_thesis}
                    touched={props.touched.doctorate_degree_thesis}
                  />

                  <InputField
                    id="areasOfExpertise"
                    name="areas_of_expertise"
                    label={t("Areas of expertise and/or teaching specializations")}
                    requiredStyle={true}
                    validate={validateIsFilled(t("This field is required"))}
                    className="form-control"
                    errors={props.errors.areas_of_expertise}
                    touched={props.touched.areas_of_expertise}
                  />

                  <GroupField
                    type="checkbox"
                    id="facultyTeachingExperience"
                    name="faculty_teaching_experience"
                    label={t("Faculty teaching experience")}
                    help_text={t("You can skip this question if applying for a staff position. Please choose all of the types of undergraduate and graduate teaching experiences that apply to you.")}
                    placeholder=""
                    choices={{
                      [t("Taught in residential higher education in face-to-face settings.")]: "residential",
                      [t("Served as a subject matter expert (SME) working with an educational technologist.")]: "subject-matter-expert",
                      [t("Facilitated an online higher education course using materials developed by someone else.")]: "facilitated-online-higher-education",
                      [t("No teaching experience in higher education.")]: "no-teaching-experience",
                      [t("Taught an online higher education course that I developed from scratch.")]: "developed-and-taught-online",
                    }}
                    className="form-control"
                    errors={props.errors.faculty_teaching_experience}
                    touched={props.touched.faculty_teaching_experience}
                  />

                  <FileUploadField
                    id="transcripts"
                    name="transcripts"
                    label={t("Unofficial transcripts")}
                    help_text={t("These are required if you will be teaching but can be emailed later if you don't have them now")}
                    validate={
                      notRequired(
                        validateFileType(
                          ["application/pdf", "image/png", "image/jpeg"],
                          t("Only files of type pdf, png, and jpg are allowed.")
                        ),
                        validateMaxFileSize(
                          settings.maxFileSize,
                          t(
                            "The file must be less than {{size}}.",
                            {size: settings.maxFileSizeString},
                          ),
                        ),
                      )
                    }
                    className="form-control"
                  />
                </div>
              </div>

              <div className="card m-3">
                <div className="card-body">
                  <div className="row m-md-3 my-2">
                    <div className="col-12">
                      <h2 className="card-title">{t("Other information")}</h2>
                    </div>
                  </div>
                  <InputField
                    id="currentJob"
                    name="current_job"
                    label={t("Current job/ministry")}
                    requiredStyle={true}
                    help_text={t("Please enter the name of your current occupation")}
                    validate={validateIsFilled(t("This field is required"))}
                    className="form-control"
                    errors={props.errors.current_job}
                    touched={props.touched.current_job}
                  />

                  <InputField
                    id="churchName"
                    name="church_name"
                    label={t("Church")}
                    requiredStyle={true}
                    help_text={t("Please enter the name of the church that you currently attend.")}
                    validate={validateIsFilled(t("This field is required"))}
                    className="form-control"
                    errors={props.errors.church_name}
                    touched={props.touched.church_name}
                  />

                  <InputField
                    id="churchDenominationName"
                    name="church_denomination_name"
                    label={t("Church denomination")}
                    help_text={t("Please include the name of the group of which your church is a part.")}
                    requiredStyle={true}
                    validate={validateIsFilled(t("This field is required"))}
                    className="form-control"
                    errors={props.errors.church_denomination_name}
                    touched={props.touched.church_denomination_name}
                  />

                  <InputField
                    id="missionsExperience"
                    name="missions_experience"
                    label={t("Missions experience")}
                    help_text={t("Please list the locations in which you have missions experience (including mission trips).")}
                    className="form-control"
                    errors={props.errors.missions_experience}
                    touched={props.touched.missions_experience}
                    as="textarea"
                  />

                  <InputField
                    id="countries"
                    name="countries"
                    label={t("Countries")}
                    requiredStyle={true}
                    help_text={t("Please list the countries to which you have traveled (include the country where you live now).")}
                    placeholder={t("Example: Boliva, United Kingdom, France")}
                    validate={validateIsFilled(t("This field is required"))}
                    className="form-control"
                    errors={props.errors.countries}
                    touched={props.touched.countries}
                  />

                  <InputField
                    id="languages"
                    name="languages"
                    label={t("Languages")}
                    help_text={[
                      <Trans
                        key="list-the-languages-you-speak"
                        i18nKey="Please list the languages that you speak (including your first language) and indicate level of proficiency"
                      >
                        Please list the languages that you speak (including your first language) and indicate <a key="link" href="https://careers.state.gov/faq-items/language-proficiency-definitions/">level of proficiency</a>
                      </Trans>
                    ]}
                    requiredStyle={true}
                    placeholder={t("Example: Swahili: Native, English: Full Professional Proficiency, Spanish: Limited Working Proficiency")}
                    validate={validateIsFilled(t("This field is required"))}
                    className="form-control"
                    errors={props.errors.languages}
                    touched={props.touched.languages}
                  />

                  <GroupField
                    type="radio"
                    id="agreeWithStatementOfFaith"
                    name="agree_with_statement_of_faith"
                    label={[
                      <Trans
                        key="statement-of-faith"
                        i18nKey='Having read our statement of faith, are your beliefs consistent with the terms of the covenant?'
                      >
                        Having read our <a key="link" href="https://missional.university/about/guiding-documents/faith-statement/">statement of faith</a>, are your beliefs consistent with the terms of the covenant?
                      </Trans>
                    ]}
                    validate={validateIsFilled(t("This field is required"))}
                    requiredStyle={true}
                    placeholder=""
                    choices={{[t("Yes")]: "Yes", [t("No")]: "No"}}
                    className="form-control agree-with-statement-of-faith"
                    errors={props.errors.agree_with_statement_of_faith}
                    touched={props.touched.agree_with_statement_of_faith}
                  />

                  {
                    props.values.agree_with_statement_of_faith === "No" && <InputField
                      id="agreeWithStatementOfFaithWhy"
                      name="agree_with_statement_of_faith_why"
                      label={t("Why do you disagree with the statement of faith?")}
                      requiredStyle={true}
                      placeholder={t("Please explain why you disagree.")}
                      className="form-control"
                      errors={props.errors.agree_with_statement_of_faith_why}
                      touched={props.touched.agree_with_statement_of_faith_why}
                      as="textarea"
                    />
                  }

                  <GroupField
                    type="radio"
                    id="agreeWithEthosStatement"
                    name="agree_with_ethos_statement"
                    label={[
                      <Trans
                        key="ethos-statement"
                        i18nKey="Having read our ethos statement, are your beliefs consistent with the statement?"
                      >
                        Having read our <a key="link" href="https://missional.university/about/guiding-documents/ethos-statement/">ethos statement</a>, are your beliefs consistent with the statement?
                      </Trans>
                    ]}
                    validate={validateIsFilled(t("This field is required"))}
                    requiredStyle={true}
                    placeholder=""
                    choices={{[t("Yes")]: "Yes", [t("No")]: "No"}}
                    className="form-control agree-with-ethos-statement"
                    errors={props.errors.agree_with_ethos_statement}
                    touched={props.touched.agree_with_ethos_statement}
                  />

                  {
                    props.values.agree_with_ethos_statement === "No" && <InputField
                      id="agreeWithEthosStatementWhy"
                      name="agree_with_ethos_statement_why"
                      label={t("Why do you disagree with the ethos statement?")}
                      requiredStyle={true}
                      placeholder={t("Please explain why you disagree.")}
                      className="form-control"
                      errors={props.errors.agree_with_ethos_statement_why}
                      touched={props.touched.agree_with_ethos_statement_why}
                      as="textarea"
                    />
                  }

                  <FileUploadField
                    id="cv"
                    name="cv"
                    label={t("Curriculum vitae")}
                    help_text={t("Please attach a complete CV (include presentations, publications and dissertation if applicable).")}
                    validate={
                      isRequired(
                        t("This field is required"),
                        validateFileType(
                          ["application/pdf"],
                          t("Only files of type pdf are allowed.")
                        ),
                        validateMaxFileSize(
                          settings.maxFileSize,
                          t(
                            "The file must be less than {{size}}.",
                            {size: settings.maxFileSizeString},
                          ),
                        ),
                      )
                    }
                    requiredStyle={true}
                    className="form-control"
                    setFieldValue={props.setFieldValue}
                    errors={props.errors.cv}
                    touched={props.touched.cv}
                  />

                  <FileUploadField
                    id="photo"
                    name="photo"
                    label={t("Photo")}
                    help_text={t("Please attach a high-resolution large, color photo with a neutral background that can be used on the Missional University website.")}
                    validate={
                      isRequired(
                        t("This field is required"),
                        validateFileType(
                          ["application/pdf", "image/png", "image/jpeg"],
                          t("Only files of type pdf, png and jpg are allowed.")
                        ),
                        validateMaxFileSize(
                          settings.maxFileSize,
                          t(
                            "The file must be less than {{size}}.",
                            {size: settings.maxFileSizeString},
                          ),
                        ),
                      )
                    }
                    requiredStyle={true}
                    className="form-control"
                    setFieldValue={props.setFieldValue}
                    errors={props.errors.photo}
                    touched={props.touched.photo}
                  />

                  <InputField
                    id="testimony"
                    name="testimony"
                    label={t("Statement of personal salvation & testimony")}
                    help_text={t("Please write a brief testimony of your personal salvation experience and describe your personal relationship with God today. Please write your response in English or Spanish.")}
                    validate={validateIsFilled(t("This field is required"))}
                    requiredStyle={true}
                    className="form-control agree-with-statement-of-faith"
                    as="textarea"
                    errors={props.errors.testimony}
                    touched={props.touched.testimony}
                  />
                  <div className="row">
                    <div className="col-12">
                      <Field type="hidden" id="muApplicantUuid" name="muApplicantUuid"></Field>
                      <div className="submit-button-area">
                        {
                          hasErrorAndIsTouched(props.errors, props.touched) ? <span className="errors">
                            {t("The form is not completed correctly. Please resolve the errors above and resubmit the form.")}
                          </span> : ""
                        }
                        {
                          failureMessage ? <span className="errors">
                            {failureMessage}
                          </span> : ""
                        }
                        <Button
                          type="submit"
                          variant="primary"
                          className="mx-md-4 my-md-3"
                          autoComplete="off"
                          disabled={!!props.isSubmitting}
                        >
                          {t("Send Application")}
                        </Button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default JobApplication;
