import i18n from "i18next";
import "libphonenumber-js-utils";
import validator from "validator";


export const getUUID = () => {
  function ff(s) {
    var pt = (Math.random().toString(16) + "000000000").substr(2, 8);
    return s ? "-" + pt.substr(0, 4) + "-" + pt.substr(4, 4) : pt ;
  }
  return ff() + ff(true) + ff(true) + ff();
}

export const validateEmail = (message) => {
  return (value) => {
    if (!validator.isEmail(value)) {
      return message;
    }
  }
}

const phoneNumberErrorMessages = {
  2: i18n.t("The phone number is too short."),
  3: i18n.t("The phone number is too long."),
  4: i18n.t("The phone number is not valid."),
};

export const validatePhoneNumber = (message) => {
  return (number) => {
    if (!window.intlTelInputUtils.isValidNumber(number)) {
      const errorKey = window.intlTelInputUtils.getValidationError(number);
      if (errorKey in phoneNumberErrorMessages) {
        return phoneNumberErrorMessages[errorKey];
      }
      return message;
    }
  }
}

export const validateLength = (minlength, maxlength, message) => {
  return (text) => {
    if(!validator.isLength(text, {min: minlength, max: maxlength})) {
      return message;
    }
  }
}

export const validateMaxFileSize = (maxFileSize, message) => {
  /* (maxFileSize is in bytes) */
  return (file) => {
    if(file.size > maxFileSize) {
      return message;
    }
  }
}

export const validateFileType = (acceptableFileTypes, message) => {
  return (file) => {
    if(acceptableFileTypes.indexOf(file.type) === -1) {
      return message || `Only files of type ${ acceptableFileTypes.join(", ") } are allowed.`
    }
  }
}

export const isRequired = (message, ...validators) => {
  return (text) => {
    if (!text) {
      return [message];
    }
    const errors = validators?.map((validator) => {
      return validator(text);
    }).filter((error) => {return error;});

    if (errors.length > 0) {
      return errors;
    }
  };
}

export const notRequired = (...validators) => {
  return (text) => {
    if (text) {
      const errors = validators?.map((validator) => {
        return validator(text);
      }).filter((error) => {return !!error;});

      if (errors.length > 0) {
        return errors;
      }
    }
  };
}

export const validateIsFilled = (message) => {
  return isRequired(message, ()=>{});
};

export const hasErrorAndIsTouched = (errors, touched) => {
  return Object.entries(errors).map(([key, value]) => {
    return touched[key];
  }).filter((value) => {return !!value;}).length > 0;
};
