import { useField } from 'formik';

const FileUploadField = ({label, ...props}) => {
  const [, meta, helpers] = useField(props);
  const { setValue, setTouched } = helpers;

  return (
    <div key={props.name} className={"row mb-3 m-md-3 " + (meta.error && meta.touched ? "errors" : "")}>
      <label
        htmlFor={props.id}
        className={"col-md-4 form-label" + (props.requiredStyle ? " required" : "")}
      >
        <span className="label-text">{label}</span>
        <div>
          <small>{props.help_text}</small>
        </div>
      </label>
      <div className="col-md-8">
        <input
          id={props.id}
          name={props.name}
          type="file"
          onChange={(event) => {
            setValue(event.currentTarget.files[0]);
          }}
          onBlur={(event) => {
            setTouched(true, true);
          }}
          className={props.className}
        ></input>
        {meta.error && meta.touched && meta.error.map((error) => {
          return <div key={error} className="validation-error">{error}</div>
        })}
      </div>

    </div>
  );
};

export default FileUploadField;
