import settings from "../settings";

export const submitApplication = async (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([k, v]) => {
      formData.append(k, v);
    })

    const result = await fetch(`${ settings.baseApiUri }/application/`, {
      method: "post",
      body: formData,
    });

    return await result.json();
};
