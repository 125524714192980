import './Navigation.scss';
import logocircle from '../../assets/logo-circle.svg';
import { useTranslation } from "react-i18next";


function Navigation() {
  const { t, } = useTranslation();

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <a className="navbar-brand" href="/">
        <img src={logocircle} className="logo" alt="logo" />{t("Jobs @ Missional University")}
      </a>
    </nav>
  );
}

export default Navigation;
