import { Field } from "formik";
import "./inputs.scss";


const InputField = ({label, errors, touched, requiredStyle, ...props}) => {
  return (
    <div key={props.id} className={"row mb-3 m-md-3 " + (errors && touched ? "errors" : "")}>
      <label
        key="label"
        htmlFor={props.id}
        className={"col-md-4" + (requiredStyle ? " required" : "")}
      >
        <span className="label-text">{label}</span>
        <div>
          <small>{props.help_text}</small>
        </div>
      </label>
      <div className="col col-md-8">
        <Field
          {...props}
        ></Field>
        {errors && touched && errors.map((error) => {
          return <div key={error} className="validation-error">{error}</div>
        })}
      </div>
    </div>
  );
};

export default InputField;
