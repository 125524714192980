import './Footer.scss';

import { useTranslation } from "react-i18next";


function Footer() {
  const { t, } = useTranslation();

  return (
    <footer>
      <div className="container mt-5">
        <div className="row">
          <div className="col px-5 pt-5 pb-4">
            <h2 className="mb-4">{t("About Missional University")}</h2>
            <p>
              {t("We are a team of passionate people whose goal is to expand the mission of God through a disruptive educational process.  We support faculty and staff as they facilitate missional learners from across the globe.")}
            </p>
            <p>
              {t("Our goal is to enable Christians to merge their faith, career, and service in the mission of God in the world.")}
            </p>
          </div>
        </div>
      </div>

      <div className="postfooter">
        <div className="container px-5 pt-4">
          <div className="row justify-content-between">
            <div className="col-12 col-md-6">
                <span className="d-inline-block">
                  {t("Copyright © 2021")} <a href="https://missional.university">{t("Missional University")}</a>.
                </span>
                <span className="d-inline-block mx-1">{t("All rights reserved.")}</span>
            </div>
            <div className="col-12 col-md-auto">
              <div className="d-none d-md-block float-right mx-1">
                {t("Empowered by Missional International")}
              </div>
              <div className="d-md-none d-block ml-1">
                {t("Empowered by Missional International")}
              </div>
            </div>
          </div>
        </div>
      </div>

    </footer>
  );
}

export default Footer;
