import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import settings from "./settings.js";

import { fr, en, es, pt_br } from "./locales";

const resources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  "pt-BR": {
    translation: pt_br,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // pass i18n down to react-i18next
  .init({
    resources,
    debug: settings.debug,
    nonExplicitSupportedLngs: true,
    cleanCode: true,
    detection: {
      order: ["querystring", "navigator"],
      lookupQuerystring: "locale"
    },
  })

export default i18n;
