import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import settings from "./settings.js";


Sentry.init({
  debug: settings.debug,
  dsn: settings.sentry_dsn,
  integrations: [new Integrations.BrowserTracing()],
  release: settings.version,
  environment: settings.environment,

  tracesSampleRate: 1.0,
});

export default Sentry;
