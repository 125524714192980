/* eslint-disable no-unused-expressions, no-sequences */
import settings from "./settings";


const loadMautic = () => {
  return new Promise((resolve, reject) => {
    (function(w,d,t,u,n,a,m){w["MauticTrackingObject"]=n;
        w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t),
        m=d.getElementsByTagName(t)[0];a.id="mtc-script";a.async=1;a.src=u;m.parentNode.insertBefore(a,m)
    })(window, document, "script", settings.mautic_script_uri, "mt");

    var script = document.querySelector("#mtc-script");
    script.addEventListener("load", function() {
      resolve(window.mt);
    });
  });
}

function mautic() {
  if (settings.environment === "production") {
    if (!mautic._instance) {
      mautic._instance = loadMautic();
    }

    mautic._instance.then((mt) => {
      mt(...arguments);
    });
  }
}

export default mautic;
