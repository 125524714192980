import "./inputs.scss";
import { Field, useField } from "formik";


const GroupField = ({choices, label, errors, touched, ...props}) => {
  const [, , helpers] = useField(props);
  const { setTouched } = helpers;

  return (
    <div key={props.id} className={"row m-md-3" + (errors && touched ? " errors" : "")}>
      <div key="label-text" id={props.id} className={"col-md-4 form-label" + (props.requiredStyle ? " required" : "")}>
        <span className="label-text">{label}</span>
        <div>
          <small>{props.help_text}</small>
        </div>
      </div>
      <div key="choices" className="col-md-8" role="group" aria-labelledby={props.id}>
        {
          Object.entries(choices).map(([label, value]) => {
            return (
              <label key={value} className="mb-4">
                <Field
                  type={props.type}
                  name={props.name}
                  value={value}
                  className="m-2"
                  onBlur={(event) => {
                    setTouched(true, true);
                  }}
                />
                {label}
              </label>
            )
          })
        }
        {errors && touched && errors.map((error) => {
          return <div key={error} className="validation-error">{error}</div>
        })}
      </div>
    </div>
  );
};

export default GroupField;
