import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next';


const Success = () => {
  const { t, } = useTranslation();

  return (
    <>
      <header>
        <h1>{t("Thank you!")}</h1>
      </header>

        <div className="row">
          <div className="col px-5">
            <p>
              {t("Thank you for submitting your application. A human resources representative will review it and get back to you.")}
            </p>

            <p>
              <Trans
                key="please-check-your-email"
                i18nKey="Please check your email for updates from human resources. Due to the large number of applications we receive, it may take 4 to 6 weeks before the review will be completed. Please be patient."
              >
                Please <strong key="check-email">check your email for updates</strong> from human resources. Due to the large number of applications we receive, <strong key="be-patient"> it may take 4 to 6 weeks</strong> before the review will be completed. Please be patient.
              </Trans>
            </p>

            <a href="https://missional.university/">
              {t("Click here to browse the Missional University home page.")}
            </a>
          </div>
        </div>

    </>
  );
}
export default Success;
